<template>
  <div class="item" @click="showInfo" :style="img_src(item.urgencyDegree)">
    <div class="tip">{{ Filter(flow_urgency_degree, item.urgencyDegree) }}</div>
    <div class="itemContent flex flex-ai-fe">
      <div class="left">
        <div class="fileName">{{ item.name }}</div>
        <div class="fileNumber fileName">
          {{ item.documentType == 1 ? '来文字号' : '发文字号' }}：{{ item.documentNumber }}
        </div>
        <div class="fileCreater">
          <span>{{ item.documentType == 1 ? '经办人：' : '拟稿人：' }} </span>
          <a-tag class="tags flex0" color="blue" v-for="(o, i) in item.draftId" :key="i">
            <OpenData :type="o.type || 'userName'" :openid="o.departmentId || o.userId" :corpid="o.corpId"></OpenData>
          </a-tag>
        </div>
        <div class="fileCreateDate fileCreater">创建日期：{{ item.createTime.substr(0, 10) }}</div>
      </div>
      <div class="right flex" @click.stop="">
        <slot name="todo">
          <a-button ghost type="primary" @click="acceptBill(item)">签收</a-button>
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import minxin from '../minxin'
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  mixins: [minxin],
  components: {
    OpenData,
  },
  methods: {
    img_src(num) {
      if (num) {
        let img = require(`../../images/urgency${num}.png`)
        return {
          backgroundImage: `url(${img})`,
        }
      }
      return {}
    },
    showInfo() {
      this.$emit('see', this.item)
    },
    Filter(arr, s) {
      if (!s) return ''
      const values = arr.filter((item) => Number(item.code) === s)
      if (values.length > 0) {
        return values[0].value
      }
    },
  },
}
</script>
<style lang="less" scoped>
.item {
  padding: 15px 10px;
  background: #ffffff;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 9px;
  // background-image: url('../../images/itemIcon.png');
  background-size: 91px 46px;
  background-repeat: no-repeat;
  background-position-x: right;
  position: relative;
  .tip {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    position: absolute;
    top: 12px;
    right: 11px;
    line-height: 1;
  }
  .itemContent {
    justify-content: space-between;
    .right {
      flex-direction: column;
      justify-content: flex-end;
      align-items: stretch;
      button {
        margin: 0 0 3px;
      }
    }
  }
  .left {
    line-height: 1;
    .fileName {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .fileNumber {
      margin: 10px 0;
    }
    .fileCreater {
      color: #999999;
      font-size: 11px;
    }
    .fileCreateDate {
      margin-top: 15px;
    }
  }
}
.flex {
  display: flex;
}
</style>
